import { useCheckoutServiceStore } from "@/utils/hooks/stores/checkout"
import ImagePayoutLoader from '@/assets/images/payout-loader.png'
import ImagePorterBadge from '@/assets/images/porter-badge.png'
import {
  FaCheckCircle as IconCheckCircle,
  FaRegDotCircle as IconDotCircle
} from "react-icons/fa";
import { Card } from "../components";
import { prefixDeterminer } from "@/utils/formatters";

type TrackingProps = {
  className?: string
}

export const Tracking: React.FC<
  TrackingProps
> = () => {
  const getCheckoutService = useCheckoutServiceStore(
    (state) => state.getCheckoutService
  )

  const [state, _] = getCheckoutService()
  const { site, customer, submission } = state.context.session

  let firstName, lastName
  if (customer?.mailing_address?.name?.first) firstName = customer.mailing_address.name.first
  if (customer?.mailing_address?.name?.last) lastName = customer.mailing_address.name.last

  const payoutMethod = ((method: 'etransfer_send_money' | 'credit_send') => {
    switch (method) {
      case 'etransfer_send_money':
        return 'e-Transfer'
      case 'credit_send':
        return 'credit/debit'
    }
  })(submission.payout_method)
  const payoutMethodPrefix = prefixDeterminer('a', payoutMethod)
  const payoutMethodPrefixCapitalized = `${payoutMethodPrefix.at(0)?.toUpperCase()}${payoutMethodPrefix.slice(1)}`

  const siteName = site.name

  return (
    <>
      <div className='flex flex-row gap-20'>
        <div>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-start items-center'>
              <div className='w-[416px]'>
                <img
                  src={ImagePayoutLoader}
                  className='w-full h-full -my-12 -ml-28'
                ></img>
              </div>
            </div>
            <div className='flex flex-row justify-start'>
              <div className='max-w-[430px] flex flex-col gap-2'>
                <h6 className='text-[24px]'>Hey {firstName},</h6>
                <h3 className='text-[36px] leading-tight'>Your payment should arrive by March 15<span className='text-[20px] align-super'>th</span>.</h3>
              </div>
            </div>
          </div>
        </div>
        <Card className='w-[490px] h-[520px] bg-white p-8'>
          <div className='flex'>
            <div className='h-9 rounded-lg overflow-hidden'>
              <img className='h-full' src={ImagePorterBadge}></img>
            </div>
          </div>
          <div className='mt-6'>
            <div className='flex flex-col gap-4'>

              <div className='flex flex-row gap-3'>
                <div>
                  <IconCheckCircle className='mt-1 w-6 h-6'/>
                </div>
                <div>
                  <div>
                    <h6 className='text-[18px] font-bold'>Payment Request Received</h6>
                  </div>
                </div>
              </div>
              <div className='flex flex-row gap-3'>
                <div>
                  <IconCheckCircle className='mt-1 w-6 h-6'/>
                </div>
                <div>
                  <div>
                    <h6 className='text-[18px] font-bold'>Payment Request Approved</h6>
                  </div>
                </div>
              </div>
              <div className='flex flex-row gap-3'>
                <div>
                  <IconCheckCircle className='mt-1 w-6 h-6'/>
                </div>
                <div>
                  <div>
                    <h6 className='text-[18px] font-bold'>Payment Method Selected</h6>
                  </div>
                  <p className='text-[16px]'>{firstName} {lastName.at(0)}. selected {payoutMethod} as the preferred a payment option to receive a payment from {siteName}</p>
                </div>
              </div>
              <div className='flex flex-row gap-3'>
                <div>
                  <IconDotCircle className='mt-1 w-6 h-6'/>
                </div>
                <div>
                  <div>
                    <h6 className='text-[18px] font-bold'>Payment Successfully Completed</h6>
                  </div>
                  <p className='text-[16px]'>{payoutMethodPrefixCapitalized} has been successfully sent.</p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}
