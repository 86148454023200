import {
  Card, CardHeader, CardBody, CardFooter,
} from "../components"
import { Loader } from "@/components/ui"
import { IoIosLock as IconLock } from "react-icons/io";
import { MainCardHeader } from "./main-card-header"

type LoadingNextCardProps = {
  className?: string
}

export const LoadingNextCard: React.FC<
  LoadingNextCardProps
> = () => {
  return (
    <>
      <Card>
        <CardHeader>
          <MainCardHeader/>
        </CardHeader>
        <CardBody>
          <div className='relative w-full h-full'>
            <Loader/>
          </div>
        </CardBody>
        <CardFooter>
          <p className='text-[11px] text-[#a1a39d]'>
            <span className='inline-flex items-center gap-1'><IconLock className='fill-green-400'/> Your data is secured and not sold</span>
          </p>
        </CardFooter>
      </Card>
      <div className='flex justify-center items-center'>
        <p className='text-[11px] text-[#a1a39d]'>
          By proceeding you agree to our <a href='https://paybilt.com/terms-conditions/' className='text-[11px] text-[#4e535c]'>terms of service</a> and <a href='https://paybilt.com/privacy/' className='text-[11px] text-[#4e535c]'>privacy policy</a>.
        </p>
      </div>
    </>
  )
}
