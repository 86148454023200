// @ts-ignore
import { useParams } from '@/router'
import * as themes from '@/themes/payout/[id]'
import { useMachine } from '@xstate/react'
import { payoutMachine } from '@/utils/hooks/machines/payout_machine'
import { useEffect } from 'react'
import { useCheckoutServiceStore } from '@/utils/hooks/stores/checkout'
import { Loader } from '@/components/ui'

const PageComponent: React.FC = () => {
  const params = useParams('/:language/payout/:id')

  const attachCheckoutService = useCheckoutServiceStore(
    (state) => state.attachCheckoutService
  )

  const checkoutService = useMachine(payoutMachine, {
    context: {
      session: {
        uid: params.id
      }
    }
  })

  attachCheckoutService(checkoutService)

  const [state, send] = checkoutService
  const currentState = state.toStrings()[state.toStrings().length - 1]
  
  useEffect(() => {
    if (/start.done$/.test(currentState)) {
      send("HANDLE_SUCCESS")
    }

    if (/start.error$/.test(currentState)) {
      // DO NOTHING
    }
  }, [state.value])

  if (/start.loading$/.test(currentState)) {
    return <Loader/>
  }

  if (/start.error$/.test(currentState)) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        {/* HACK: set a default for this */}
        <h3>{state.context.errorStates.start.errors?.[0]?.title}</h3>
        <p>{state.context.errorStates.start.errors?.[0]?.detail}</p>
      </div>
    )
  }

  const { config } = state.context.session

  switch (config.occ.theme.name) {
    case 'default':
      return <themes.Porter />
  }
}

export default PageComponent
