import { z } from "zod"

export const schemaMailingAddress = z.object({
  name: z
    .object({
      first: z.string().min(1),
      last: z.string().min(1)
    }),
  country: z.string().min(1),
  address: z.string().min(1),
  unit: z.union([
    z.string().min(1),
    z.undefined()
  ]),
  state: z.string().min(1),
  city: z.string().min(1),
  zip_code: z
    .string()
    .transform((value) => {
      return value.replace(' ', '')
    })
    .pipe(z.union([
      z.string().regex(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z][0-9][A-Z][0-9]$/),
      z.string().regex(/^\d{5}$/)
    ]))
})
