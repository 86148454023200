import { Input, InputProps } from './input'
import { IMaskInputProps } from 'react-imask'
import { IMaskMixin } from 'react-imask';

const InputMask = IMaskMixin<HTMLInputElement, IMaskInputProps<HTMLInputElement> & InputProps>(({inputRef, ...props}) => (
  <Input
    {...props}
    ref={inputRef}  // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
  />
));

export { InputMask }
export * from 'react-imask'
