import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center ring-offset-background transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "rounded-2xl bg-gradient-to-t from-[#121c2e] to-[#121c2ecc] font-normal text-[20px] text-[#ffffff] shadow-[0px_0px_0px_2px_#FFFFFF33_inset]",
        destructive: "",
        outline: "",
        secondary: "",
        ghost: "",
        minimal: "",
        link: "",
      },
      size: {
        default: "py-4 px-5 ",
        sm: "",
        lg: "",
        icon: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
                // <div
                //   style={{
                //     boxShadow: '0px 0px 0px 2px #FFFFFF33 inset'
                //   }}
                //   className=''
                // >
                //   <span className=''>
                //     Next
                //   </span>
                // </div>
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
