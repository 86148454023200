import React from 'react'
import Lottie from 'lottie-react'
import loadingCircleLottie from '@/assets/lotties/loading_circle.json'

type Props = {}

const Loader: React.FC<Props> = () => {
  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Lottie
        animationData={loadingCircleLottie}
        loop={true}
        style={{
          width: '256px',
          height: '256px'
        }}
      />
    </div>
  )
}

export { Loader }
