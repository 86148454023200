import * as React from "react"
import { usePlacesWidget, ReactGoogleAutocompleteProps } from 'react-google-autocomplete'
import { Global, css } from '@emotion/react'
import { Input, InputProps } from './input'
import { cn } from '@/utils'

type Props = {
  places: ReactGoogleAutocompleteProps
}

const InputPlaces = React.forwardRef<HTMLInputElement, Props & InputProps>(
  ({ className, type, ...props }, ref) => {
  const { ref: placesRef } = usePlacesWidget<HTMLInputElement>({...props.places})
  const inputProps = Object.keys(props)
    .filter((prop) => {
      return !['places'].includes(prop)
    })
    .reduce((accumulator: Record<string, any>, propKey: string) => {
      accumulator[propKey] = (props as Record<string, any>)[propKey]
      return accumulator
    }, {})

  return (
    <>
      <Global
        styles={css`
          /* Reset */
          .pac-container {
            box-shadow: none;
            border: none;
          }

          .pac-item {
            border: none;
          }

          .pac-logo::after {
            display: none;
          }

          /* Styles */
          .pac-container {
            border-style: solid;
            border-color: hsl(var(--border));
            border-width: 1px;
            margin-top: 0.2rem;
            border-radius: calc(var(--radius) - 4px);
            padding: 0.24rem;
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
            animation-name: pop--in;
            animation-duration: 0.1s;
          }

          .pac-item {
            /* padding: 0.375rem 0.5rem; */
            height: 31.2px;
            border-radius: calc(var(--radius) - 4px);
          }

          .pac-item:hover {
            background: hsl(var(--accent));
          }

          @keyframes pop--in {
            0% {
              transform: scale(96%) translateY(-8px);
            }
            100% {
              transform: scale(100%);
            }
          }
        `}
      />
      <Input
        className={cn(className)}
        ref={(element) => {
          // @ts-ignore
          placesRef.current = element
          
          // @ts-ignore
          if (ref) ref.current = element
        }}
        {...inputProps}
      />
    </>
  )
})

export { InputPlaces }
