import { cn } from "@/utils"
import { PropsWithChildren } from "react"


type CardProps = {
  className?: string
} & PropsWithChildren

export const Card: React.FC<
  CardProps
> = ({
  className,
  children
}) => {
  return (
    <div
      className={cn(
        'w-[600px] h-[720px] bg-[#f5f7fa] flex flex-col rounded-2xl border shadow-md overflow-hidden',
        className
      )}
    >
      {children}
    </div>
  )
}

type CardHeaderProps = {
  className?: string
} & PropsWithChildren

export const CardHeader: React.FC<
  CardHeaderProps
> = ({
  className,
  children
}) => {
  return (
    <div
      className={cn(
        'p-6 flex flex-row gap-4 justify-center',
        className
      )}
    >
      {children}
    </div>
  )
}

type CardBodyProps = {
  className?: string
} & PropsWithChildren

export const CardBody: React.FC<
  CardBodyProps
> = ({
  className,
  children
}) => {
  return (
    <div
      className={cn(
        'bg-white p-6 border-b rounded-t-2xl grow flex flex-col justify-between',
        className
      )}
    >
      {children}
    </div>
  )
}

type CardFooterProps = {
  className?: string
} & PropsWithChildren

export const CardFooter: React.FC<
  CardFooterProps
> = ({
  className,
  children
}) => {
  return (
    <div
      className={cn(
        'p-4 flex flex-row gap-4 justify-center',
        className
      )}
    >
      {children}
    </div>
  )
}
