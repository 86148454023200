import { z } from 'zod'
import { parse } from 'date-format-parse'


export const schemaVisaPAN = z
  .string({
    required_error: 'Invalid card number',
    invalid_type_error: 'Invalid card number',
  })
  .regex(/^4[0-9]{12}(?:[0-9]{3})?$/, {
    message: 'Invalid card number'
  })

export const schemaMastercardPAN = z
  .string({
    required_error: 'Invalid card number',
    invalid_type_error: 'Invalid card number',
  })
  .regex(/^(2|5)[1-5][0-9]{14}$/, {
    message: 'Invalid card number'
  })

export const schemaAmexPAN = z
  .string({
    required_error: 'Invalid card number',
    invalid_type_error: 'Invalid card number',
  })
  .regex(/^3[47][0-9]{13}$/, {
    message: 'Invalid card number'
  })

export const schemaCreditCardPAN = z.union([
  schemaVisaPAN,
  schemaMastercardPAN,
  schemaAmexPAN
], {
  required_error: 'Invalid card number',
  invalid_type_error: 'Invalid card number',
});

export function schemaCreditCardExpiry(format: string) {
  return z
    .string()
    .refine((value) => {
      const valueAsDate = parse(value, format)
      const currentDate = new Date()

      // Check if the format is valid
      if (isNaN(valueAsDate.getTime())) {
        return false
      }

      // Check if credit card has expired
      if (valueAsDate.getTime() < currentDate.getTime()) {
        return false
      }

      return true
    }, {
      message: 'Invalid expiration',
    })
}

export const schemaCreditCardCVV = z.string().regex(/^\d{3}$/);
