import { useEffect, useState } from "react"

type Method = {
  id: string
  name: string
  description: string
  image: {
    url: string
    alt: string
  }
}

export function useMethodsAPI(enabled: Method[], featured: Method[]) {
  const isExpandable = featured.length > 0 ? true : false
  const shouldShowAll = featured.length === 0 ? true : false

  const [isExpanded, setIsExpanded] = useState<boolean>(shouldShowAll)
  const [data, setData] = useState<Array<Method>>(
    isExpanded ? enabled : featured
  )

  useEffect(() => {
    setData(isExpanded ? enabled : featured)
  }, [
    enabled,
    featured,
    isExpanded
  ])

  return {
    data,
    isExpandable,
    isExpanded,
    toggleExpansion: () => {
      if (!isExpandable) return
      setIsExpanded(!isExpanded)
    },
  }
}
