import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import { cn } from '@/utils'
import { InputProps, inputVariants } from '@/components/ui/input'

const InputPhone: React.FC<InputProps & PhoneInputProps> = ({ className, variant, ...props }) => {
  return (
    <PhoneInput
      containerClass='w-full'
      specialLabel=''
      disableDropdown={true}
      showDropdown={false}
      inputClass={cn(inputVariants({ variant, className }))}
      {...props as any}
    />
  )
}

export { InputPhone }
