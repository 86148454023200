import { useForm } from "react-hook-form"
import {
  Button,
  Card, CardHeader, CardBody, CardFooter, Label,
} from "../components"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  schemaCreditCardExpiry,
  schemaCreditCardPAN,
  schemaPhoneNumber,
} from "@/utils/validators"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  InputContainer,
  InputMask,
  InputPhone,
} from "@/components/ui"
import { useCheckoutServiceStore } from "@/utils/hooks/stores/checkout"
import { IoIosLock as IconLock } from "react-icons/io";
import ImageVisa from '@/assets/images/visa.webp'
import ImageMastercard from '@/assets/images/mastercard-1.png'
import { LazyLoadImage } from "react-lazy-load-image-component"
import { FormEvent, useEffect, useState } from "react"
import { MainCardHeader } from "./main-card-header"
import { cn } from "@/utils"
import { extractExpiration } from "@/utils/formatters"
import { construct, crush, shake } from "radash"
import { parse } from "date-format-parse"

const creditCardExpiryFormat = 'MM / YYYY'

const formSchema = z.object({
  full_name: z
    .string({
      required_error: 'Invalid full name',
      invalid_type_error: 'Invalid full name'
    })
    .regex(/^.+ .+/, {
      message: 'Please provide a full name',
    }),
  email: z.string().email(),
  phone: schemaPhoneNumber,
  payout_details: z.object({
    credit_card: z.object({
      new_card: z
        .object({
          pan: z
            .string()
            .transform((value) => {
              return value.replaceAll(' ', '')
            })
            .pipe(schemaCreditCardPAN),
          expiry: schemaCreditCardExpiry(creditCardExpiryFormat),
        })
    })
  })
})

type CollectCreditSendProps = {
  className?: string
}

export const CollectCreditSend: React.FC<
  CollectCreditSendProps
> = () => {
  const getCheckoutService = useCheckoutServiceStore(
    (state) => state.getCheckoutService
  )

  const [state, send] = getCheckoutService()
  const { currency_code, amount, customer } = state.context.session
  const [cardType, setCardType] = useState<'visa' | 'mastercard'>()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    shouldFocusError: false,
  })

  const {
    payout_details
  } = form.getValues()

  useEffect(() => {
    if (customer?.mailing_address) {
      const mailingAddress = customer?.mailing_address

      if (mailingAddress.name?.first && mailingAddress.name?.last) {
        form.setValue('full_name', `${mailingAddress.name.first} ${mailingAddress.name.last}`)
      }
    }

    if (customer?.email?.value) form.setValue('email', customer.email.value)
    if (customer?.phone?.value) form.setValue('phone', (customer.phone.value as string).substring(1))
  }, [])

  // Dynamically figure out the card type
  useEffect(() => {
    const pan = payout_details?.credit_card.new_card?.pan

    if (!pan) {
      setCardType(undefined)
      return
    }

    if (pan.length < 0) {
      setCardType(undefined)
      return
    }

    if (['4'].includes(pan[0])) {
      setCardType('visa')
      return
    }

    if (['2', '5'].includes(pan[0])) {
      setCardType('mastercard')
      return
    }

    setCardType(undefined)
  }, [payout_details?.credit_card?.new_card?.pan])

  // Submit the form
  function onSubmit(values: z.infer<typeof formSchema>) {
    const firstName = values.full_name.split(' ')[0]
    const lastName = values.full_name.split(' ')[1]
    const { expiration_month, expiration_year } = extractExpiration(
      parse(values.payout_details.credit_card.new_card.expiry, creditCardExpiryFormat)
    )

    const transformed = {
      email: values.email,
      phone: values.phone,
      payout_details: {
        credit_card: {
          new_card: {
            pan: values.payout_details.credit_card.new_card.pan,
            expiration_month,
            expiration_year,
          }
        }
      },
      mailing_address: {
        name: {
          first: firstName,
          last: lastName
        }
      }
    }

    const customer = construct(shake(crush(transformed)))

    send('SUBMIT', {
      data: {
        customer
      }
    })
  }

  const getImageCreditCard = () => {
    switch (cardType) {
      case 'visa':
        return (
          <LazyLoadImage
            wrapperClassName='overflow-hidden min-w-[34px] w-[34px] h-[24px] rounded-sm'
            className='bg-[#eee] object-cover h-full w-full'
            src={ImageVisa}
          />
        )
      case 'mastercard':
        return (
          <LazyLoadImage
            wrapperClassName='overflow-hidden min-w-[34px] w-[34px] h-[24px] rounded-sm'
            className='bg-[#eee] object-cover h-full w-full'
            src={ImageMastercard}
          />
        )
      default:
        return (
          <div className='min-w-[34px] w-[34px] h-[24px] rounded-sm bg-[#eee]' />
        )
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <MainCardHeader />
        </CardHeader>
        <CardBody>
          <Form {...form}>
            <form
              className='h-full flex flex-col justify-between gap-5'
              onSubmit={form.handleSubmit(onSubmit)}>
              <div className='flex flex-col gap-4'>
                <div className='grid grid-cols-12 gap-2'>
                  <h6 className='col-span-12 text-[16px] font-[500]'>
                    Personal Information
                  </h6>
                  <FormField
                    control={form.control}
                    name='full_name'
                    render={({ field }) => (
                      <FormItem className='col-span-12'>
                        <FormControl>
                          <Input
                            className={cn(
                              // Traverses paths to find error mapping
                              'full_name'.split('.').reduce((errors: any, path) => {
                                return errors?.[path]
                              }, form.formState.errors)
                                ? 'outline outline-1 outline-destructive'
                                : ''
                            )}
                            placeholder="Full Name"
                            data-testid={`input-full_name`}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage variant='normal'/>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='email'
                    render={({ field }) => (
                      <FormItem className='col-span-12'>
                        <FormControl>
                          <Input
                            className={cn(
                              // Traverses paths to find error mapping
                              'email'.split('.').reduce((errors: any, path) => {
                                return errors?.[path]
                              }, form.formState.errors)
                                ? 'outline outline-1 outline-destructive'
                                : ''
                            )}
                            placeholder="Email"
                            data-testid={`input-email`}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage variant='normal'/>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='phone'
                    render={({ field }) => (
                      <FormItem className='col-span-12'>
                        <FormControl>
                          <InputPhone
                            className={cn(
                              // Traverses paths to find error mapping
                              'phone'.split('.').reduce((errors: any, path) => {
                                return errors?.[path]
                              }, form.formState.errors)
                                ? 'outline outline-1 outline-destructive'
                                : ''
                            )}
                            inputProps={{
                              'data-testid': 'input-phone'
                            }}
                            {...field} />
                        </FormControl>
                        <FormMessage variant='normal'/>
                      </FormItem>
                    )}
                  />
                </div>
                <div className='grid grid-cols-12 gap-2'>
                  <h6 className='col-span-12 text-[16px] font-[500]'>
                    Bank Details
                  </h6>
                  <FormField
                    control={form.control}
                    name='payout_details.credit_card.new_card.pan'
                    render={({ field }) => {
                      // Copy the onChange handler and preserver the context
                      const onChange = field.onChange.bind(field)

                      // Extract the card type on each change
                      field.onChange = (event: FormEvent<HTMLInputElement>) => {
                        const value = event.currentTarget.value

                        if (['4'].includes(value[0])) {
                          setCardType('visa')
                        } else if (['2', '5'].includes(value[0])) {
                          setCardType('mastercard')
                        } else {
                          setCardType(undefined)
                        }

                        onChange(event)
                      }

                      return (
                        <FormItem className='col-span-9 space-y-1'>
                          <Label>Card number</Label>
                          <InputContainer
                            className={cn(
                              'flex flex-row items-center gap-2',
                              // Traverses paths to find error mapping
                              'payout_details.new_card.pan'.split('.').reduce((errors: any, path) => {
                                return errors?.[path]
                              }, form.formState.errors)
                                ? 'outline outline-1 outline-destructive'
                                : ''
                            )}
                          >
                            {getImageCreditCard()}
                            <FormControl>
                              <InputMask
                                data-testid={`input-credit_card-pan`}
                                variant='minimal'
                                mask={[{
                                  mask: '0000 0000 0000 0000'
                                }]}
                                eager={true}
                                {...field}
                              />
                            </FormControl>
                          </InputContainer>
                          <FormMessage variant="normal" />
                        </FormItem>
                      )
                    }}
                  />
                  <FormField
                    control={form.control}
                    name={'payout_details.credit_card.new_card.expiry'}
                    render={({ field }) => (
                      <FormItem className='col-span-3 space-y-1'>
                        <Label className='inline-flex h-[15px]'></Label>
                        <FormControl>
                          <InputMask
                            className={cn(
                              // Traverses paths to find error mapping
                              'payout_details.new_card.expiry'.split('.').reduce((errors: any, path) => {
                                return errors?.[path]
                              }, form.formState.errors)
                                ? 'outline outline-1 outline-destructive'
                                : ''
                            )}
                            data-testid={`input-credit_card-expiry`}
                            placeholder='MM / YYYY'
                            mask='mm / yyyy'
                            eager={true}
                            blocks={{
                              // @ts-ignore
                              mm: { mask: IMask.MaskedRange, from: 1, to: 12 },
                              // @ts-ignore
                              yyyy: { mask: IMask.MaskedRange, from: 0, to: 9999 }
                            }}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage variant="normal" />
                      </FormItem>
                    )}
                  />
                </div >
              </div>
              <Button
                type='submit'
              >
                Receive ${amount.toLocaleString(undefined, { minimumFractionDigits: 2 })} {currency_code}
              </Button>
            </form>
          </Form>
        </CardBody>
        <CardFooter>
          <p className='text-[11px] text-[#a1a39d]'>
            <span className='inline-flex items-center gap-1'><IconLock className='fill-green-400' /> Your data is secured and not sold</span>
          </p>
        </CardFooter>
      </Card>
      <div className='flex justify-center items-center'>
        <p className='text-[11px] text-[#a1a39d]'>
          By proceeding you agree to our <a href='https://paybilt.com/terms-conditions/' className='text-[11px] text-[#4e535c]'>terms of service</a> and <a href='https://paybilt.com/privacy/' className='text-[11px] text-[#4e535c]'>privacy policy</a>.
        </p>
      </div>
    </>
  )
}
