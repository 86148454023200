import { FormControl, FormField, FormItem, RadioGroup } from "@/components/ui"
import { cn } from "@/utils"
import { UseFormReturn } from "react-hook-form"
import { CreditSendPayoutMethod, ETransferSendMoneyPayoutMethod } from "."
import { useCheckoutServiceStore } from "@/utils/hooks/stores/checkout"
import { useMethodsAPI } from "@/utils/hooks/transactions"
import { useEffect } from "react"


type PayoutMethodsProps = {
  className?: string
  form: UseFormReturn<any>
  formPaths: {
    payout_method: string
  }
}

export const PayoutMethods: React.FC<
  PayoutMethodsProps
> = ({
  className,
  form,
  formPaths,
}) => {
  const getCheckoutService = useCheckoutServiceStore(
    (state) => state.getCheckoutService
  )

  const [state] = getCheckoutService()
  const sitePayoutMethods = state.context.session?.site?.payout?.methods

  const payoutMethodsAPI = useMethodsAPI(
    sitePayoutMethods.enabled,
    sitePayoutMethods.recommended
  )

  useEffect(() => {
    form.setValue(formPaths.payout_method, payoutMethodsAPI.data[0].id)
  }, [])

  return (
    <div className={cn(className)}>
      <FormField
        control={form.control}
        name={formPaths.payout_method}
        render={({ field }) => (
          <FormItem className='flex flex-col gap-2'>
            <FormControl>
              <RadioGroup
                className='flex flex-col gap-3'
                onValueChange={field.onChange}
                defaultValue={field.value}
              >
                {payoutMethodsAPI.data.map((element) => {
                  const { id } = element

                  switch (id) {
                    case 'etransfer_send_money':
                      return (
                        <ETransferSendMoneyPayoutMethod key={id}/>
                      )
                    case 'credit_send':
                      return (
                        <CreditSendPayoutMethod key={id}/>
                      )
                  }
                })}
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  )
}
