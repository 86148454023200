import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { convertColor } from '@/utils/colors'
import { crush, construct } from 'radash'

 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export type Theme = {
  colors: {
    background: string
    foreground: string
    primary: {
      background: string
      foreground: string
    }
    secondary: {
      background: string
      foreground: string
    }
  }
}

export function loadTheme (theme: Theme) {
  document.documentElement.style.setProperty('--background', convertColor.hex.hsl(theme.colors.background))
  document.documentElement.style.setProperty('--foreground', convertColor.hex.hsl(theme.colors.foreground))

  document.documentElement.style.setProperty('--ring', convertColor.hex.hsl(theme.colors.primary.background))
  document.documentElement.style.setProperty('--primary', convertColor.hex.hsl(theme.colors.primary.background))
  document.documentElement.style.setProperty('--primary-foreground', convertColor.hex.hsl(theme.colors.primary.foreground))

  document.documentElement.style.setProperty('--secondary', convertColor.hex.hsl(theme.colors.secondary.background))
  document.documentElement.style.setProperty('--secondary-foreground', convertColor.hex.hsl(theme.colors.secondary.foreground))
}


export function overlapObject<
  Overlappee extends Record<string, any>,
  Overlapper extends Record<string, any>,
>(overlappee: Overlappee, overlapper: Overlapper): Overlappee & Overlapper {
  const crushedOverlappee = crush(overlappee) as Record<string, any>
  const crushedOverlapper = crush(overlapper) as Record<string, any>

  for (const key of Object.keys(crushedOverlapper)) {
    crushedOverlappee[key] = crushedOverlapper[key]
  }

  return construct(crushedOverlappee) as (Overlappee & Overlapper)
}
