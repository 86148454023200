import { create }  from 'zustand'
import { devtools } from 'zustand/middleware'

type CheckoutService = {
  service?: any
  attachCheckoutService: (machine: any) => void
  getCheckoutService: () => any
}

export const useCheckoutServiceStore = create<CheckoutService>()(devtools((set, get) => ({
  attachCheckoutService: (service: any) => {
    set(() => ({
      service
    }))
  },
  getCheckoutService: () => {
    const { service } = get()
    return service
  }
}), {
  enabled: ['development', 'staging'].includes(import.meta.env.VITE_APP_ENVIRONMENT)
}))
