import { FormControl, FormItem, FormLabel } from "@/components/ui"
import { RadioGroupItem } from "../../components"
import { cn } from "@/utils"
import ImageETransfer from '@/assets/images/etransfer.png'


type ETransferSendMoneyPayoutMethodProps = {
  className?: string
}

export const ETransferSendMoneyPayoutMethod: React.FC<
  ETransferSendMoneyPayoutMethodProps
> = ({
  className
}) => {
  return (
    <div className={cn(className)}>
      <FormItem>
        <div
          className={cn(
            'relative w-full h-full p-2 border rounded-lg flex flex-row justify-between items-center cursor-pointer',
          )}
        >
          <FormLabel htmlFor="etransfer_send_money" >
            <div className='flex flex-row justify-center items-center gap-3'>
              <div className='h-10 aspect-square'>
                <img
                  className='h-full w-full'
                  src={ImageETransfer}
                ></img>
              </div>
              <span className='text-[17px]'>e-Transfer®</span>
            </div>
          </FormLabel>
          <FormControl>
            <RadioGroupItem
              value="etransfer_send_money"
              id="etransfer_send_money"
              variant="bin"
            />
          </FormControl>
        </div>
      </FormItem>
    </div>
  )
}
