import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

// Use default language for namespace types
import type common from "public/locales/en/common.json";
import type validation from "public/locales/en/validation.json";
import type glossary from "public/locales/en/glossary.json";

// Define the resources so that the linter can detect it for autocomplete
declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: 'common'
    resources: {
      common: typeof common
      validation: typeof validation
      glossary: typeof glossary
    }
  }
}

// Instantiate the language
i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.PROD ? true : false,
    fallbackLng: "en",
    defaultNS: 'common',
    ns: ['common', 'validation', 'glossary'],
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['path', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      lookupFromPathIndex: 0
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
  })

i18n.on('languageChanged', lng => {
  console.log(lng);
});

export default i18n;
