// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/:language/loading`
  | `/:language/payment/:id`
  | `/:language/payout/:id`

export type Params = {
  '/:language/loading': { language: string }
  '/:language/payment/:id': { language: string; id: string }
  '/:language/payout/:id': { language: string; id: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<
  Path,
  Params,
  ModalPath
>()
export const { redirect } = utils<Path, Params>()
