import colorConverter from 'color-convert'

function cssNormalizeColor (value: [number, number, number], type: 'hsl') {
  switch (type) {
    case 'hsl':
      return value
        .map((element, index) => {
          if (index === 0) return element
          return `${element}%`
        })
        .join(', ')
  }
}

export const convertColor = {
  hex: {
    hsl: (value: string) => cssNormalizeColor(colorConverter.hex.hsl(value), 'hsl')
  }
}

