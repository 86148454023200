import { cn } from "@/utils"
import ImagePaybilt from '@/assets/images/paybilt.png'


type NavigationBarProps = {
  className?: string
}

export const NavigationBar: React.FC<
  NavigationBarProps
> = ({
  className
}) => {
    return (
      <nav
        className={cn(
          'flex flex-row justify-between items-center py-5',
          className
        )}
      >
        <div className='h-8'>
          <img
            className='h-full'
            src={ImagePaybilt}
          ></img>
        </div>
        <div>
          <div className='bg-white rounded-3xl border shadow-sm h-10 aspect-square flex justify-center items-center'>
            <a href='#' className='text-[15px]'>FR</a>
          </div>
        </div>
      </nav>
    )
}
