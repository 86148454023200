/**
 * Adds a prefix determiner to a word using proper vowel or consonant identification
 * @param determiner a determiner word used for prefixing
 * @param word a word to use after the prefix
 * @returns a human-readable phrase
 */
export function prefixDeterminer(determiner: "a", word: string) {
  if (word.length === 0) return "";

  switch (determiner) {
    case "a":
      // Prefix an if first letter of word is a vowel
      if (["a", "e", "i", "o", "u"].includes(word[0])) {
        return `an ${word}`;
        // Prefix a if first letter of word is a consonant
      } else {
        return `a ${word}`;
      }
  }
}

export function extractExpiration(expiration: Date) {
  return {
    expiration_month: (expiration.getMonth() + 1).toString().padStart(2, '0'),
    expiration_year: expiration.getFullYear().toString()
  }
}
