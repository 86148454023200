// @ts-ignore
import { useParams } from '@/router'
import { Loader } from '@/components/ui'
import { useQuery } from '@tanstack/react-query'
import { StateMachine } from 'xstate'
import { useMachine } from '@xstate/react'
import { useCheckoutServiceStore } from '@/utils/hooks/stores/checkout'
import { Suspense, lazy, useEffect } from 'react'

const Default = lazy(() => import('@/themes/payment/[id]/default'))
const Custom = lazy(() => import('@/themes/payment/[id]/custom'))

const PageComponent: React.FC = () => {
  const params = useParams('/:language/payment/:id')

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
  } = useQuery<any, any>({
    queryKey: ['checkout'],
    queryFn: async () => {
      let response

      try {
        response = await
          fetch(`/api/payment/session/${params.id}/process/start`, {
            method: 'GET'
          })
            .then(response => response.json())
      } catch (error) {
        console.error(error)

        return Promise.reject({
          title: 'Something went wrong!',
          detail: 'Please try again later.'
        })
      }

      if ('errors' in response) {
        return Promise.reject({
          title: response.errors?.[0]?.title,
          detail: response.errors?.[0]?.detail
        })
      }

      console.log('loading machine')

      // Choose the checkout machine
      const machine = await(async (theme: string) => {
        switch (theme) {
          case 'default': {
            const { checkoutMachine } = await import('@/utils/hooks/machines/checkout_machine')
            console.log('choosing checkout')
            return checkoutMachine
          }
          case 'custom': {
            const { donationMachine } = await import('@/utils/hooks/machines/donation_machine')
            console.log('choosing donation')
            return donationMachine
          }
        }
      })(response.data.attributes.config.occ.theme.name)

      if (!machine) {
        return Promise.reject({
          title: 'Something went wrong!',
          detail: 'Please try again later.'
        })
      }

      return Promise.resolve({
        response,
        machine
      })
    }
  })

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        {/* HACK: set a default for this */}
        <h3>{error.title}</h3>
        <p>{error.detail}</p>
      </div>
    )
  }

  if (isSuccess) {
    return (
      <CheckoutServiceLoader
        id={params.id}
        machine={data.machine}
        data={data.response}
      />
    )
  }
}

type CheckoutServiceLoaderProps = {
  id: string
  machine: StateMachine<any, any, any>
  data: any
}

export const CheckoutServiceLoader: React.FC<CheckoutServiceLoaderProps> = ({
  id,
  machine,
  data,
}) => {
  const attachCheckoutService = useCheckoutServiceStore(
    (state) => state.attachCheckoutService
  )

  // Create the machine
  const checkoutService = useMachine(machine, {
    context: {
      session: {
        uid: id
      }
    }
  })

  attachCheckoutService(checkoutService)

  const [state, send] = checkoutService
  const currentState = state.toStrings()[state.toStrings().length - 1]

  useEffect(() => {
    if (/bootstrapping.idle/.test(currentState)) {
      send('LOAD', {
        data
      })
    }
  }, [state.value])

  if (/bootstrapping.idle/.test(currentState)) {
    return <div></div>
  }
    
  const theme = data.data.attributes.config.occ.theme.name

  console.log(theme)

  return (
    <>
      {theme === 'default' &&
        <Suspense fallback={<Loader/>}>
          <Default/>
        </Suspense>
      }
      {theme === 'custom' &&
        <Suspense fallback={<Loader/>}>
          <Custom/>
        </Suspense>
      }
    </>
  )
}

export default PageComponent
