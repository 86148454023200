import { RadioGroupItem } from "../../components"
import { cn } from "@/utils"
import ImageCreditSend from '@/assets/images/card.png'
import { FormControl, FormItem, FormLabel } from "@/components/ui"


type CreditSendPayoutMethodProps = {
  className?: string
}

export const CreditSendPayoutMethod: React.FC<
  CreditSendPayoutMethodProps
> = ({
  className
}) => {
  return (
    <div className={cn(className)}>
      <FormItem>
        <div
          className={cn(
            'relative w-full h-full p-2 border rounded-lg flex flex-row justify-between items-center cursor-pointer',
          )}
        >
          <FormLabel htmlFor="credit_send" >
            <div className='flex flex-row justify-center items-center gap-3'>
              <div className='h-10 aspect-square'>
                <img
                  className='h-full w-full'
                  src={ImageCreditSend}
                ></img>
              </div>
              <span className='text-[17px]'>Depost Funds to Debit/Credit Card</span>
            </div>
          </FormLabel>
          <FormControl>
            <RadioGroupItem
              value="credit_send"
              id="credit_send"
              variant='bin'
            />
          </FormControl>
        </div>
      </FormItem>
    </div>
  )
}
