import { useForm } from "react-hook-form"
import {
  Button,
  Card, CardHeader, CardBody, CardFooter,
} from "../components"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  schemaMailingAddress,
  schemaPhoneNumber,
} from "@/utils/validators"
import { Form } from "@/components/ui"
import { useCheckoutServiceStore } from "@/utils/hooks/stores/checkout"
import { IoIosLock as IconLock } from "react-icons/io";
import { useEffect } from "react"
import { MainCardHeader } from "./main-card-header"
import { PayoutMethods } from "."

const formSchema = z.object({
  email: z.string().email().optional(),
  phone: schemaPhoneNumber.optional(),
  payout_method: z.enum(['etransfer_send_money', 'credit_send']),
  mailing_address: schemaMailingAddress
})

type SelectPayoutMethodProps = {
  className?: string
}

export const SelectPayoutMethod: React.FC<
  SelectPayoutMethodProps
> = () => {
  const getCheckoutService = useCheckoutServiceStore(
    (state) => state.getCheckoutService
  )

  const [state, send] = getCheckoutService()
  const { customer } = state.context.session

  const email = customer?.email?.value

  let addresses = []
  if (customer?.mailing_address?.address) addresses.push(customer?.mailing_address?.address)
  if (customer?.mailing_address?.unit) addresses.push(`Unit ${customer?.mailing_address?.unit}`)
  addresses = [addresses.join(' ')] // Concatenates address and units without the comma
  if (customer?.mailing_address?.zip_code) addresses.push(customer?.mailing_address?.zip_code)
  const fullAddress = addresses.join(', ') // Concatenates everything else with the comma

  const names = []
  if (customer?.mailing_address?.name?.first) names.push(customer.mailing_address.name.first)
  if (customer?.mailing_address?.name?.last) names.push(customer.mailing_address.name.last)
  const initials = names.map(name => name.at(0)).join('')
  const fullName = names.join(' ')

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })

  useEffect(() => {
    if (customer?.email?.value) form.setValue('email', customer.email.value)

    if (customer?.phone?.value) form.setValue('phone', (customer.phone.value as string).substring(1))

    if (customer?.payout_method?.value) form.setValue('payout_method', customer.payout_method.value)

    if (customer?.mailing_address) {
      if (customer.mailing_address.name?.first) form.setValue('mailing_address.name.first', customer.mailing_address.name.first)
      if (customer.mailing_address.name?.last) form.setValue('mailing_address.name.last', customer.mailing_address.name.last)
      if (customer.mailing_address.country) form.setValue('mailing_address.country', customer.mailing_address.country)
      if (customer.mailing_address.address) form.setValue('mailing_address.address', customer.mailing_address.address)
      if (customer.mailing_address.unit) form.setValue('mailing_address.unit', customer.mailing_address.unit)
      if (customer.mailing_address.city) form.setValue('mailing_address.city', customer.mailing_address.city)
      if (customer.mailing_address.state) form.setValue('mailing_address.state', customer.mailing_address.state)
      if (customer.mailing_address.zip_code) form.setValue('mailing_address.zip_code', customer.mailing_address.zip_code)
    }
  }, [])

  function onSubmit(values: z.infer<typeof formSchema>) {
    const event = {
      data: {
        customer: {
          ...values
        }
      }
    }

    switch (values.payout_method) {
      case 'etransfer_send_money':
        send('ETRANSFER_SEND_MONEY', event)
        break
      case 'credit_send':
        send('CREDIT_SEND', event)
        break
    }
  }

  console.log(form.formState.errors)

  return (
    <>
      <Card>
        <CardHeader>
          <MainCardHeader/>
        </CardHeader>
        <CardBody>
          <Form {...form}>
            <form
              className='h-full flex flex-col justify-between gap-5'
              onSubmit={form.handleSubmit(onSubmit)}>
              <div className='flex flex-col gap-2'>
                <div className='flex flex-row gap-2'>
                  <div className='h-10 aspect-square bg-[#d1ccf4] rounded-3xl flex justify-center items-center text-[11px]'>
                    {initials}
                  </div>
                  <div className='flex flex-col items-start grow'>
                    <p className='text-[16px]'>{fullName}</p>
                    <div className='flex flex-row gap-6 w-full'>
                      <div className='flex flex-row gap-1'>
                        <img src='https://paybilt-assets.s3.ca-central-1.amazonaws.com/email-assets/Icons/mail-01.png'></img>
                        <span className='text-[12px]'>{email}</span>
                      </div>
                      <div className='flex flex-row grow gap-1'>
                        <img src='https://paybilt-assets.s3.ca-central-1.amazonaws.com/email-assets/Icons/Pin%2C+Location.png'></img>
                        <span className='text-[12px]'>{fullAddress}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='mt-2 mb-5 opacity-30'></hr>
                <h6 className='text-[16px] font-[600]'>How would you like to receive funds</h6>
                <PayoutMethods
                  form={form}
                  formPaths={{
                    payout_method: 'payout_method'
                  }}
                />
              </div>
              <Button
                type='submit'
              >
                Next
              </Button>
            </form>
          </Form>
        </CardBody>
        <CardFooter>
          <p className='text-[11px] text-[#a1a39d]'>
            <span className='inline-flex items-center gap-1'><IconLock className='fill-green-400'/> Your data is secured and not sold</span>
          </p>
        </CardFooter>
      </Card>
      <div className='flex justify-center items-center'>
        <p className='text-[11px] text-[#a1a39d]'>
          By proceeding you agree to our <a href='https://paybilt.com/terms-conditions/' className='text-[11px] text-[#4e535c]'>terms of service</a> and <a href='https://paybilt.com/privacy/' className='text-[11px] text-[#4e535c]'>privacy policy</a>.
        </p>
      </div>
    </>
  )
}
