import * as React from "react"
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"

import { cn } from "@/utils"
import { VariantProps, cva } from "class-variance-authority"

const radioVariants = cva(
  "",
  {
    variants: {
      variant: {
        default: "h-4 w-4 aspect-square rounded-full border ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        bin: "disabled:cursor-not-allowed disabled:opacity-50 absolute top-0 right-0 bottom-0 left-0 w-full h-full"
      }
    },
    defaultVariants: {
      variant: "default"
    },
  }
)

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

export interface RadioGroupItemProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
  VariantProps<typeof radioVariants> {
  asChild?: boolean
}

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({ className, variant = 'default', asChild = false, ...props }, ref) => {
  if (variant === 'default') {
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(radioVariants({ variant, className }))}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-end">
          <div className='w-4 h-4 aspect-square border-[#404855] border-4 rounded-full'>
          </div>
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
    )
  }

  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(radioVariants({ variant, className }))}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="absolute top-0 right-0 bottom-0 left-0 w-full h-full z-10">
        <div className='w-full h-full p-2 outline outline-[#404855] outline-2 rounded-md flex flex-row justify-end items-center'>
          <div className='w-3 h-3 aspect-square outline outline-[#404855] -outline-offset-4 outline-4 rounded-full'>
          </div>
        </div>
      </RadioGroupPrimitive.Indicator>
      <div className='absolute top-0 right-0 bottom-0 left-0 w-full h-full'>
        <div className='w-full h-full p-2 flex flex-row justify-end items-center'>
          <div className='w-3 h-3 aspect-square outline outline-[#e2e8f0] outline-1 rounded-full'>
          </div>
        </div>
      </div>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupItem }
