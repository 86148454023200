import { useCheckoutServiceStore } from "@/utils/hooks/stores/checkout"


type MainCardHeaderProps = {
  className?: string
}

export const MainCardHeader: React.FC<
  MainCardHeaderProps
> = () => {
  const getCheckoutService = useCheckoutServiceStore(
    (state) => state.getCheckoutService
  )

  const [state, _] = getCheckoutService()
  const { site, currency_code, amount, udfs } = state.context.session

  const simpleURL = site.url.split('/')[2]

  return (
    <>
      <div className='h-14 w-14 bg-white rounded-2xl overflow-hidden'>
        <img src={site.logo.url} alt={site.logo.alt}></img>
      </div>
      <div className='h-14 grow flex flex-col items-start justify-center gap-2'>
        <p className='text-[20px] -mt-1 -mb-2'>{site.name}</p>
        <div className='flex flex-row items-center gap-2 text-[#4e535c]'>
          <a href={site.url} className='text-[12px]'>{simpleURL}</a>
          <span className='text-[9px]'>|</span>
          <span className='text-[12px]'>{udfs.at(8)}</span>
        </div>
      </div>
      <div className='h-14 flex flex-col items-end'>
        <p className='text-[13px] text-[#747370]'>We're sending you</p>
        <h3 className='flex flex-row items-end gap-1'>
          <span className='text-[28px] -mt-1 -mb-[7px]'>${amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
          <span className='text-[14px] font-bold text-[#747370]'>{currency_code}</span>
        </h3>
      </div>
    </>
  )
}
