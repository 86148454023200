import { Theme, loadTheme } from '@/utils'
import { useCheckoutServiceStore } from '@/utils/hooks/stores/checkout'
import {
  NavigationBar,
  LoadingNextCard,
  SelectPayoutMethod,
  CollectCreditSend,
  PayoutSent,
  Tracking,
} from './sections'

export const Porter: React.FC = () => {
  const getCheckoutService = useCheckoutServiceStore(
    (state) => state.getCheckoutService
  )

  const [state, _] = getCheckoutService()
  const currentState = state.toStrings()[state.toStrings().length - 1]
  const { config } = state.context.session

  // Load default theme
  if (config.occ.theme.name !== 'default') return

  const theme: Theme = {
    colors: {
      background: '#f5f5f4',
      foreground: '#000000',
      primary: {
        background: '#404855',
        foreground: '#404855',
      },
      secondary: {
        background: '#f5f5f4',
        foreground: '#f5f5f4',
      }
    }
  }

  loadTheme(theme)

  return (
    <div className='w-screen h-screen flex flex-row justify-center'>
      <div className='relative max-w-[1100px] w-full h-full flex flex-col'>
        <div className='absolute left-0 right-0'>
          <NavigationBar />
        </div>
        <div className='w-full flex grow justify-center items-center'>
          <main className='flex flex-col gap-4'>
            {/collecting.payout_method/.test(currentState) && <SelectPayoutMethod />}
            {/collecting.credit_send/.test(currentState) && <CollectCreditSend />}
            {/checking_out.submitting.loading/.test(currentState) && <LoadingNextCard />}
            {/checking_out.submitting.done/.test(currentState) && <PayoutSent />}
            {/checking_out.tracking/.test(currentState) && <Tracking />}
            {/* <LoadingNextCard/> */}
            {/* <SelectPayoutMethod/> */}
            {/* <CollectCreditSend/> */}
            {/* <PayoutSent/> */}
            {/* <Tracking/> */}
          </main>
        </div>
      </div>
    </div>
  )
}
