import { createMachine, assign } from 'xstate'
import ky from 'ky'
import { v4 as uuid } from 'uuid'
import { overlapObject } from '@/utils'
import { construct, shake, crush, omit } from 'radash'

const httpBackend = ky.extend({
  throwHttpErrors: false,
  timeout: false
})

type UpdatedRecord = {
  [key: string]: any
  updated_at: string
}

function getMostRecent(values: UpdatedRecord[]) {
  let mostRecentValue
  let mostRecentTime = 0

  for (const value of values) {
    const updatedAt = new Date(value.updated_at)
    const updatedAtTime = updatedAt.getTime()

    if (updatedAtTime > mostRecentTime) {
      mostRecentValue = value
      mostRecentTime = updatedAtTime
    }
  }

  return mostRecentValue
}

function mergeRecent(a: UpdatedRecord | undefined, b: UpdatedRecord | undefined) {
  const isAUsable = a && Object.keys(a).length > 0
  const isBUsable = b && Object.keys(b).length > 0

  if (isAUsable && isBUsable) {
    const isAMoreRecentThanB = new Date(a.updated_at).getTime() > new Date(b.updated_at).getTime()

    let merged: Record<string, any> = {}

    if (isAMoreRecentThanB) {
      merged = overlapObject(b, a)
    } else {
      merged = overlapObject(a, b)
    }

    return merged
  } else if (isAUsable) {
    return a
  } else if (isBUsable) {
    return b
  }

  return undefined
}

function mergeCustomers(customerA: Record<string, any> | undefined, customerB: Record<string, any> | undefined) {
  let merged: Record<string, any> = {}

  if (customerA && customerB) {
    merged.email = mergeRecent(customerA.email, customerB.email)
    merged.phone = mergeRecent(customerA.phone, customerB.phone)
    merged.mailing_address = mergeRecent(customerA.mailing_address, customerB.mailing_address)
    merged.payout_method = mergeRecent(customerA.payout_method, customerB.payout_method)
  } else if (customerA) {
    merged = customerA
  } else if (customerB) {
    merged = customerB
  }

  return merged
}

function fetchMachine(config: Record<string, any>): any {
  return {
    initial: 'loading',
    states: {
      loading: {
        invoke: {
          src: config.fetch,
          onDone: { target: 'done' },
          onError: { target: 'error' }
        }
      },
      done: {
        ...config.onDone
      },
      error: {
        ...config.onError
      }
    }
  }
}

const getCheckoutSessionDoneHandler = {
  entry: assign({
    browser: () => {
      // Check if this is a first time Browser of one-click checkout
      const rawLocalStorageBrowser = localStorage.getItem(`browser`)
      const localStorageBrowser = JSON.parse(rawLocalStorageBrowser ?? '{}')

      if (!rawLocalStorageBrowser) {
        localStorageBrowser.uid = uuid()
        localStorage.setItem(`browser`, JSON.stringify(localStorageBrowser))
      }

      return localStorageBrowser
    },
    session: (context: any, event: any) => {
      const response = event.data

      // Start off with the database session
      const databaseSession = response.data.attributes
      
      // Clean up customer session
      databaseSession.customer = construct(shake(crush(databaseSession.customer), (value) => {
        return value === null || value === undefined
      }))

      // Derive the session from the database and local storage, starting with the database
      const derivedSession = databaseSession
      derivedSession.history = []

      // Grab the local storage session
      const rawLocalStorageSession = localStorage.getItem(`session.${context.session.uid}`)
      const localStorageSession = rawLocalStorageSession ? JSON.parse(rawLocalStorageSession) : null

      // Derive customer from local storage session and database
      if (localStorageSession) {
        console.log('merging customers: database & local session')
        derivedSession.customer = mergeCustomers(databaseSession.customer, localStorageSession.customer)
        derivedSession.history = localStorageSession.history
        localStorage.setItem(`session.${context.session.uid}`, JSON.stringify(derivedSession))
        return derivedSession
      }
      // There was no local storage session so we are going to try to use the customers in local storage

      // Grab the most recent customer used across all checkout sessions
      const rawLocalStorageCustomers = localStorage.getItem(`customers`)
      const localStorageCustomers = rawLocalStorageCustomers ? JSON.parse(rawLocalStorageCustomers) : null

      // Derive customer from local storage customers and database
      if (localStorageCustomers) {
        console.log('merging customers: database & local customer')
        const localStorageCustomer = getMostRecent(localStorageCustomers)
        derivedSession.customer = mergeCustomers(databaseSession.customer, localStorageCustomer)
        localStorage.setItem(`session.${context.session.uid}`, JSON.stringify(derivedSession))
        return derivedSession
      }
      // There was no local storage customers so we give up

      // Could not derive any new information from local storage
      return databaseSession
    }
  }),
  on: {
    HANDLE_SUCCESS: { target: '#payout.collecting' }
  }
}

const getCheckoutSessionErrorHandler = {
  entry: assign({
    errors: (context: any, event: any) => {
      const response = event.data

      console.error(response)

      context.errorStates ??= {}
      context.errorStates.start = response

      return context.errorStates
    }
  }),
  on: {
    HANDLE_FAILURE: { target: '#payout.start' }
  }
}

const submitTransactionDoneHandler = {
  entry: assign({
    session: (context: any, event: any) => {
      const response = event.data
      context.session.transaction = response.data.attributes.transaction
      return context.session
    }
  }),
  on: {
    HANDLE_SUCCESS: { target: '#payout.checking_out.tracking' }
  }
}

const submitTransactionErrorHandler = {
  entry: assign({
    errors: (context: any, event: any) => {
      const response = event.data

      console.error(response)

      context.errorStates ??= {}
      context.errorStates.checking_out ??= {}
      context.errorStates.checking_out.submitting = response

      return context.errorStates
    }
  }),
  on: {
    HANDLE_RETRY: { target: '#payout.collecting' }
  }
}

export const payoutMachine = createMachine({
  predictableActionArguments: true,
  id: 'payout',
  initial: "start",
  context: {},
  states: {
    start: fetchMachine({
      fetch: 'getCheckoutSession',
      onDone: getCheckoutSessionDoneHandler,
      onError: getCheckoutSessionErrorHandler
    }),
    collecting: {
      initial: 'payout_method',
      states: {
        payout_method: {
          on: {
            ETRANSFER_SEND_MONEY: { target: 'etransfer_send_money' },
            CREDIT_SEND: { target: 'credit_send' },
          },
          exit: ['savePaymentMethod']
        },
        etransfer_send_money: {
          always: [{ target: '#payout.checking_out.saving' }]
        },
        credit_send: {
          on: {
            SUBMIT: { target: '#payout.checking_out.saving' },
            BACK: { target: 'payout_method' }
          },
          exit: ['saveCreditSend']
        }
      },
      on: {
        SUBMIT: { target: 'checking_out' },
      }
    },
    checking_out: {
      initial: 'saving',
      states: {
        saving: {
          always: [
            { target: 'submitting' }
          ],
        },
        submitting: fetchMachine({
          fetch: 'submitTransaction',
          onDone: submitTransactionDoneHandler,
          onError: submitTransactionErrorHandler
        }),
        tracking: {

        }
      }
    }
  }
}, {
  guards: {},
  actions: {
    savePaymentMethod: assign({
      session: (context: any, event: any) => {
        /**
         * ---------------------------
         * Update customer information
         * ---------------------------
         */
        context.session.customer ??= {}

        context.session.customer.email = overlapObject(
          context.session.customer.email,
          { value: event.data.customer.email }
        )

        context.session.customer.phone = overlapObject(
          context.session.customer.phone,
          { value: event.data.customer.phone }
        )

        context.session.customer.mailing_address = overlapObject(
          context.session.customer.mailing_address,
          event.data.customer.mailing_address,
        )

        context.session.customer.payout_method =
          event.data.customer.payout_method ??
          context.session.customer.payout_method

        context.session.customer.payout_details = overlapObject(
          context.session.customer.payout_details,
          event.data.customer.payout_details,
        )

        /**
         * --------------------------
         * Add submission information
         * --------------------------
         */
        const email = context.session.customer.email?.value
        const phone = context.session.customer.phone?.value
        const mailingAddress = omit(context.session.customer.mailing_address, ['updated_at'])
        const payoutMethod = context.session.customer.payout_method
        const payoutDetails = context.session.customer.payout_details

        context.session.submission ??= {}
        context.session.submission = overlapObject(context.session.submission, {
          email: email,
          phone: phone,
          mailing_address: mailingAddress,
          payout_method: payoutMethod,
          payout_details: payoutDetails
        })

        localStorage.setItem(`session.${context.session.uid}`, JSON.stringify(context.session))
        return context.session
      },
    }),
    saveCreditSend: assign({
      session: (context: any, event: any) => {
        /**
         * ---------------------------
         * Update customer information
         * ---------------------------
         */
        context.session.customer ??= {}

        context.session.customer.email = overlapObject(
          context.session.customer.email,
          { value: event.data.customer.email }
        )

        context.session.customer.phone = overlapObject(
          context.session.customer.phone,
          { value: event.data.customer.phone }
        )

        context.session.customer.mailing_address = overlapObject(
          context.session.customer.mailing_address,
          event.data.customer.mailing_address,
        )

        context.session.customer.payout_method = 
          event.data.customer.payout_method ??
          context.session.customer.payout_method

        context.session.customer.payout_details = overlapObject(
          context.session.customer.payout_details,
          event.data.customer.payout_details,
        )

        /**
         * --------------------------
         * Add submission information
         * --------------------------
         */
        const email = context.session.customer.email?.value
        const phone = context.session.customer.phone?.value
        const mailingAddress = omit(context.session.customer.mailing_address, ['updated_at'])
        const payoutMethod = context.session.customer.payout_method
        const payoutDetails = context.session.customer.payout_details

        context.session.submission ??= {}
        context.session.submission = overlapObject(context.session.submission, {
          email: email,
          phone: phone,
          mailing_address: mailingAddress,
          payout_method: payoutMethod,
          payout_details: payoutDetails
        })

        localStorage.setItem(`session.${context.session.uid}`, JSON.stringify(context.session))
        return context.session
      },
    }),
  },
  services: {
    submitTransaction: async (context: any) => {
      const response = await httpBackend
        .post(`/api/payout/session/${context.session.uid}/process/submit`, {
          json: {
            browser: { uid: context.browser.uid },
            customer: context.session.submission
          }
        })
        .json<any>()

      if ('errors' in response)
        return Promise.reject(response)

      return Promise.resolve(response)
    },
    getCheckoutSession: async (context: any) => {
      const response = await httpBackend
        .get(`/api/payout/session/${context.session.uid}/process/start`)
        .json<any>()

      if ('errors' in response)
        return Promise.reject(response)

      return Promise.resolve(response)
    }
  }
})
