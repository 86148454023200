import {
  Card, CardHeader, CardBody, CardFooter, Button,
} from "../components"
import { useCheckoutServiceStore } from "@/utils/hooks/stores/checkout"
import { IoIosLock as IconLock } from "react-icons/io";
import ImagePayoutTracker from '@/assets/images/payout-tracker.png'
import { MainCardHeader } from "./main-card-header";

type PayoutSentProps = {
  className?: string
}

export const PayoutSent: React.FC<
  PayoutSentProps
> = () => {
  const getCheckoutService = useCheckoutServiceStore(
    (state) => state.getCheckoutService
  )

  const [state, send] = getCheckoutService()
  const { currency_code, amount, customer } = state.context.session

  let firstName
  if (customer?.mailing_address?.name?.first) firstName = customer.mailing_address.name.first

  return (
    <>
      <Card>
        <CardHeader>
          <MainCardHeader/>
        </CardHeader>
        <CardBody>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-center items-center'>
              <div className='w-[380px]'>
                <img
                  src={ImagePayoutTracker}
                  className='w-full h-full -my-10'
                ></img>
              </div>
            </div>
            <div className='flex flex-row justify-center'>
              <div className='max-w-[430px] flex flex-col gap-2'>
                <h5 className='text-[24px]'>Success! ${amount.toLocaleString(undefined, {minimumFractionDigits: 2})} {currency_code} sent to {firstName}</h5>
                <p className='text-[14px] text-center'>Your information has been successfully submitted.  You can now track the current status of your request.</p>
              </div>
            </div>
          </div>
          <Button
            type='button'
            onClick={() => {
              send('HANDLE_SUCCESS')
            }}
          >
            Track Progress
          </Button>
        </CardBody>
        <CardFooter>
          <p className='text-[11px] text-[#a1a39d]'>
            <span className='inline-flex items-center gap-1'><IconLock className='fill-green-400'/> Your data is secured and not sold</span>
          </p>
        </CardFooter>
      </Card>
      <div className='flex justify-center items-center'>
        <p className='text-[11px] text-[#a1a39d]'>
          By proceeding you agree to our <a href='https://paybilt.com/terms-conditions/' className='text-[11px] text-[#4e535c]'>terms of service</a> and <a href='https://paybilt.com/privacy/' className='text-[11px] text-[#4e535c]'>privacy policy</a>.
        </p>
      </div>
    </>
  )
}
